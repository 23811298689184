import { getAudioDurationInSeconds } from '@remotion/media-utils'
import { NODE_BACKEND_URL, IS_FREE_PLATFORM, NODE_ENV } from '../../../config'
import { getDomainID, getToken, qaBackendApiInstance } from '../../../config/config'
import axios from 'axios'
import { message } from 'antd'
import { generateUniqueId } from '../core'
import { categorisedVoiceOvers, openAIVoicesList, platformVoiceOvers } from '../../../data/music'
import { correctSpelling } from '../captionCorrection/captionCorrection'
import { creditsDeduct } from './helperFunctions/mediaSearch'
import { truncate } from 'fs'
import { ReplicaClonedVoices } from '@src/data/avatarClonedVoices'

type LanguageOption = {
  value: string;
  code: string;
};
const languagesOptions: LanguageOption[] = [
  { value: 'English', code: 'en' },
  { value: 'Spanish', code: 'es' },
  { value: 'Portuguese', code: 'pt' },
  { value: 'Italian', code: 'it' },
  { value: 'Arabic', code: 'ar' },
  { value: 'Japanese', code: 'ja' },
  { value: 'Chinese', code: 'zh' },
  { value: 'German', code: 'de' },
  { value: 'Hindi', code: 'hi' },
  { value: 'French', code: 'fr' },
  { value: 'Korean', code: 'ko' },
  { value: 'Indonesian', code: 'id' },
  { value: 'Dutch', code: 'nl' },
  { value: 'Turkish', code: 'tr' },
  { value: 'Filipino', code: 'tl' },
  { value: 'Polish', code: 'pl' },
  { value: 'Swedish', code: 'sv' },
  { value: 'Bulgarian', code: 'bg' },
  { value: 'Romanian', code: 'ro' },
  { value: 'Czech', code: 'cs' },
  { value: 'Greek', code: 'el' },
  { value: 'Finnish', code: 'fi' },
  { value: 'Croatian', code: 'hr' },
  { value: 'Malay', code: 'ms' },
  { value: 'Slovak', code: 'sk' },
  { value: 'Danish', code: 'da' },
  { value: 'Tamil', code: 'ta' },
  { value: 'Ukrainian', code: 'uk' },
  { value: 'Russian', code: 'ru' },
  { value: 'Vietnamese', code: 'vi' },
];


export async function generateAiAudio(
  scriptData: any,
  store: any,
  index: any,
  setScriptDetails: any,
  setProgressBarPercent: any,
  currentProgress: number,
  progressIncrement: number,
  pagesLength: number,
  industry: string,
  style: string,
  userDetails: any,
  selectedSize: string,
  scriptPages: any,
  isAvatar?:boolean,
 
) {

  try {
    let avatarVoicelessThanThreeSec=false
    let aiVoiceID = ""
    let aiVoiceName = ""
    const randomVoice=userDetails?.purpose?.favoriteVoice?.voice_name == 'random'
    const isFavoriteVoice=userDetails?.purpose?.favoriteVoice?.voice_id ? true : false
    const favoriteName = userDetails?.purpose?.favoriteVoice?.voice_name
    const favoriteId = userDetails?.purpose?.favoriteVoice?.voice_id
    let isElevenLabFromUpfront = userDetails?.purpose?.favoriteVoice?.elevenLab
    if(!randomVoice && isFavoriteVoice){
      aiVoiceID=favoriteId
      aiVoiceName=isElevenLabFromUpfront ? favoriteName : favoriteId
    }else{
      if(userDetails?.purpose?.promptToVideo){
        if(randomVoice){
          aiVoiceID = platformVoiceOvers[selectedSize][style].voiceID
        aiVoiceName = platformVoiceOvers[selectedSize][style].name
        }else{
        aiVoiceName = favoriteName || 'alloy'
        }
        // aiVoiceName = platformVoiceOvers[selectedSize][style].name
        // aiVoiceName = userDetails?.purpose?.voiceType || 'alloy'
      }else{
        const isIndustryExists = industry in categorisedVoiceOvers
        if(isIndustryExists){
          if(randomVoice){
          aiVoiceID = categorisedVoiceOvers[industry || 'Others'][style].voiceID
  
            aiVoiceName = categorisedVoiceOvers[industry || 'Others'][style].name
      
            }else{
            aiVoiceName = favoriteName || 'alloy'
      
            }
          // aiVoiceName = categorisedVoiceOvers[industry || 'Others'][style].name
        // aiVoiceName = userDetails?.purpose?.voiceType || 'alloy'
  
        }
       else{
        // aiVoiceID = categorisedVoiceOvers['Others'][style].voiceID
          // aiVoiceName = categorisedVoiceOvers['Others'][style].name
        // aiVoiceName = userDetails?.purpose?.voiceType || 'alloy'
        if(randomVoice){
        aiVoiceID = categorisedVoiceOvers['Others'][style].voiceID
  
         aiVoiceName = categorisedVoiceOvers['Others'][style].name
    
          }else{
          aiVoiceName = favoriteName || 'alloy'
    
          }
       }
      }
    }
    
  
    // const aiVoiceID = 'LcfcDJNUP1GQjkzn1xUU'
    // const aiVoiceName = 'Emily'

    let domainId
    let token

    if (IS_FREE_PLATFORM === 'false') {
      domainId = getDomainID()
      token = getToken()
    }
    const Prevnext=getPrevNextText(scriptPages, index)
    const voiceOver = scriptPages.length-1 === index ? scriptData.voiceOver : scriptData.voiceOver+'..'
    let audioUrl:string
    
    if(userDetails?.purpose?.selectedVoiceUniversal){
      aiVoiceID=userDetails?.purpose?.selectedVoiceUniversal
      isElevenLabFromUpfront=true
    }
    if(scriptPages?.[index]?.elevenLabsAiVoiceId){
      aiVoiceID=scriptPages?.[index]?.elevenLabsAiVoiceId
      isElevenLabFromUpfront=true

    }
    if(scriptData?.aiAvatarProps?.scriptAvatar){
 
      const result = ReplicaClonedVoices.find(
        (voice) => voice.replica_id === userDetails?.purpose?.avatarReplicaId
      );
      aiVoiceID = result?.voice_id || '424nYgcAIaT6rJGwvcSc'
    }
    openAIVoicesList
    const isOpenAIVoice = openAIVoicesList.includes(
      scriptPages?.[index]?.elevenLabsAiVoiceId
    );
    if(isOpenAIVoice){
      isElevenLabFromUpfront=false
    }
   
    if(isFavoriteVoice){
      audioUrl = await aiTextToSpeech(voiceOver, aiVoiceID, domainId, aiVoiceName, isElevenLabFromUpfront, token, Prevnext.prevText, Prevnext.nextText, 30, 65)
    }else{
      audioUrl = await aiTextToSpeech(voiceOver, aiVoiceID, domainId, aiVoiceName, isElevenLabFromUpfront, token, Prevnext.prevText, Prevnext.nextText, 30, 65)
    }
    // const audioUrl = await aiTextToSpeech(voiceOver, aiVoiceID, domainId, '', true, token, Prevnext.prevText, Prevnext.nextText)

    console.log('Getting Audio duration')
    const voiceOverTextArr=scriptData.voiceOver.split(' ')
    let duration = 2

    if(audioUrl) duration = await getAudioDurationInSecondsCustom(audioUrl) // Assuming `getAudioDurationInSeconds` is a function you've defined to get the audio duration
    
    
    currentProgress += progressIncrement
    setProgressBarPercent(Math.round(currentProgress))

    let newDuration =
      duration * 1000 > store.pages[index].duration ? duration * 1000 + 500 : store.pages[index].duration + 500

    if (index === pagesLength - 1) {
      newDuration = newDuration + 1000
    }

    let captionsResult: any
    if(voiceOver.length>0){
      try {
        // console.log(userDetails)
        
        captionsResult = await generateCaptions(audioUrl, userDetails?.purpose?.language || '')
      } catch (error) {
        console.error('Caption generation failed, proceeding without captions.', error)
      }
    }else{
      console.log('no captions')
    }
 

    currentProgress += progressIncrement
    setProgressBarPercent(Math.round(currentProgress))
    // const correctedCaptionResponse = correctSpelling(captionsResult.data.words, voiceOverTextArr);
    // console.log(correctedCaptionResponse)
    await setScriptDetails((prevScript: { data: any }) => {
      const updatedScript = { ...prevScript }

      const updatedData = [...prevScript.data ? prevScript.data : prevScript]

      updatedData[index] = {
        ...updatedData[index],
        name: aiVoiceName,
        voiceId:aiVoiceID,
        elevenLabsAiVoiceId: aiVoiceID || '',
        url: audioUrl,
        isElevenApi: true,
        captions: captionsResult ? [{ words: captionsResult?.data?.words, text: scriptData.voiceOver }] : [],
        aiVoiceVolume: 1,
        sounds: [{ url: audioUrl, audioDuration: duration }],
      }

      return { ...updatedScript, data: updatedData }
    })

    if (captionsResult) {
      store.pages[index].set({
        duration: style === 'Burst' ? store.pages[index].duration || 3000 : newDuration || 3000,
        custom: {
          ...store.pages[index].custom,
          aiVoiceVolume: 1,
          sounds: [{ url: audioUrl, audioDuration: duration }],
          captions: [{ text: scriptData.voiceOver, words: captionsResult?.data?.words }],
        },
      })
    } else {
      store.pages[index].set({
        duration: style === 'Burst' ? store.pages[index].duration || 3000: newDuration || 3000,
        aiVoiceVolume: 1,
        custom: {
          ...store.pages[index].custom,
          sounds: [{ url: audioUrl, audioDuration: duration }],
        },
      })
    }
    console.log(duration)
    if(duration<3 && isAvatar){

      if(isFavoriteVoice){
        audioUrl = await aiTextToSpeech(voiceOver+' '+voiceOver, aiVoiceID, domainId, aiVoiceName, isElevenLabFromUpfront, token, Prevnext.prevText, Prevnext.nextText, 30, 65)
      }else{
        audioUrl = await aiTextToSpeech(voiceOver+' '+voiceOver, aiVoiceID, domainId, aiVoiceName, isElevenLabFromUpfront, token, Prevnext.prevText, Prevnext.nextText, 30, 65)
      }
      avatarVoicelessThanThreeSec=true
    // if(audioUrl) duration = await getAudioDurationInSecondsCustom(audioUrl) // Assuming `getAudioDurationInSeconds` is a function you've defined to get the audio duration
    
    }
    return {newDuration:newDuration, audioUrl:audioUrl, avatarVoicelessThanThreeSec:avatarVoicelessThanThreeSec}

  } catch (error) {
    console.error('Error making text-to-speech request:', error)
  }
}

export async function generateCaptions(audioUrl: any, language:string): Promise<any> {
  try {

    const languageFilter = languagesOptions.find(lang => lang.value === language);
    const languageCode = languageFilter ? languageFilter.code : '';
    const localEndPoint = axios.create({
      baseURL: `${NODE_BACKEND_URL}`,
    })

    const formData = {
      audioFileUrl: audioUrl,
      language: languageCode
    }
    const transcribeResponse = await localEndPoint.post('/api/v1/whisper/transcribe', formData)
    return transcribeResponse
  } catch (error) {
    console.error('Error transcribing audio:', error)
  }
}

export async function aiTextToSpeech(
  text: string,
  aiVoiceID: string | undefined,
  domainId: string | undefined,
  aiVoiceName: string | undefined,
  isElevenLabs: boolean,
  token: string,
  prevText:string,
  nextText:string,
  stability:number,
  similarity:number
) {
  try {
    const localEndPoint = axios.create({
      baseURL: `${NODE_BACKEND_URL}`,
    })

    const aiVoiceGenerationBody = {
      text: text,
      aiVoiceId: aiVoiceID,
      // aiVoiceId: 'WgE8iWzGVoJYLb5V7l2d',
      domainId: domainId,
      aiVoiceName: aiVoiceName?.toLowerCase(),
      isElevenLabs: isElevenLabs,
      token: token,
      isFree: IS_FREE_PLATFORM,
      platform: NODE_ENV,
      prevText:prevText,
      nextText:nextText,
      stability,
      similarity
    }

    const aiTextToSpeechResponse = await localEndPoint.post('/api/v1/ai/audio', aiVoiceGenerationBody)
    // creditsDeduct('voice_credits', 1)
    return aiTextToSpeechResponse.data
  } catch (error) {
    console.error('Error getting eleven labs audio:', error)
  }
}

export async function generateRegeneratedScripAiAudio(
  regeneratedScriptData: any,
  store: any,
  index: any,
  setScriptDetails: any,
  setProgressBarPercent: any,
  currentProgress: number,
  progressIncrement: number,
  regeneratedScript: any,
) {
  console.log(regeneratedScriptData)
  console.log(regeneratedScript)
  try {
    let domainId
    let token

    if (IS_FREE_PLATFORM === 'false') {
      domainId = getDomainID()
      token = getToken()
    }

    let audioUrl: any

    if (regeneratedScriptData.isElevenApi === true) {
      audioUrl = await aiTextToSpeech(
        regeneratedScriptData.voiceOver,
        regeneratedScriptData.elevenLabsAiVoiceId||'21m00Tcm4TlvDq8ikWAM',
        domainId,
        '',
        true,
        token,
        '',
        '',
        50,
        75
      )
    } else {
      audioUrl = await aiTextToSpeech(
        regeneratedScriptData.voiceOver,
        '21m00Tcm4TlvDq8ikWAM',
        domainId,
        regeneratedScriptData.name,
        false,
        token,
        '',
        '',
        50,
        75
      )
      // aiVoiceName = regeneratedScriptData.name
    }

    currentProgress += progressIncrement
    setProgressBarPercent(Math.round(currentProgress))

    const duration = await getAudioDurationInSeconds(audioUrl)
    let newDuration =
      duration * 1000 > store.pages[index].duration ? duration * 1000 + 500 : store.pages[index].duration + 500
    if (index === store.pages.length - 1) {
      newDuration = newDuration + 1000
    }

    let captionsResult: any
    try {
      captionsResult = await generateCaptions(audioUrl, '')
    } catch (error) {
      console.error('Caption generation failed, proceeding without captions.', error)
    }

    currentProgress += progressIncrement
    setProgressBarPercent(Math.round(currentProgress))

    await setScriptDetails((prevScript: { data: any }) => {
      const updatedScript = { ...prevScript }

      const updatedData = [...prevScript.data ? prevScript.data : prevScript]


      updatedData[index] = {
        ...updatedData[index],
        name: regeneratedScriptData.name,
        elevenLabsAiVoiceId: regeneratedScriptData.elevenLabsAiVoiceId,
        url: audioUrl,
        isElevenApi: regeneratedScriptData.isElevenApi,
        sounds: [{ url: audioUrl, audioDuration: duration }],
        captions: captionsResult ? [{ text: regeneratedScriptData.voiceOver, words: captionsResult?.data?.words }] : [],
        aiVoiceVolume: 1,
      }

      delete updatedData[index].isUpdated

      return { ...updatedScript, data: updatedData }
    })

    if (captionsResult) {
      store.pages[index].set({
        duration: newDuration,
        custom: {
          ...store.pages[index].custom,
          sounds: [{ url: audioUrl, audioDuration: duration }],
          captions: [{ text: regeneratedScriptData.voiceOver, words: captionsResult.data.words }],
        },
      })
    } else {
      store.pages[index].set({
        duration: newDuration,
        custom: {
          ...store.pages[index].custom,
          sounds: [{ url: audioUrl, audioDuration: duration }],
        },
      })
    }
  } catch (error) {
    console.error('Error making text-to-speech request:', error)
  }
}

export async function getAudioDurationInSecondsCustom(audioUrl: string): Promise<number> {
  const response = await fetch(audioUrl);
  if (!response.ok) {
      throw new Error('Failed to fetch audio file');
  }

  const audioArrayBuffer = await response.arrayBuffer();

  return new Promise((resolve, reject) => {
      const AudioContext = window.AudioContext || (window as any).webkitAudioContext;
      const audioContext = new AudioContext();

      audioContext.decodeAudioData(audioArrayBuffer, (audioBuffer) => {
          resolve(audioBuffer.duration);
      }, (error) => {
          reject(new Error('Failed to decode audio data'));
      });
  });
}
// export async function getAudioDurationInSecondsCustom(audioUrl: string): Promise<number> {
//   const response = await fetch(audioUrl);
//   if (!response.ok) {
//     throw new Error('Failed to fetch audio file');
//   }

//   const audioArrayBuffer = await response.arrayBuffer();

//   return new Promise((resolve, reject) => {
//     const AudioContext = window.AudioContext || (window as any).webkitAudioContext;

//     try {
//       const audioContext = new AudioContext();

//       // Check if the context is suspended and resume it if needed
//       if (audioContext.state === 'suspended') {
//         audioContext.resume().then(() => {
//           decodeAudio(audioContext, audioArrayBuffer, resolve, reject);
//         }).catch(reject);
//       } else {
//         decodeAudio(audioContext, audioArrayBuffer, resolve, reject);
//       }
//     } catch (error) {
//       reject(new Error('Failed to create AudioContext'));
//     }
//   });
// }

// function decodeAudio(
//   audioContext: AudioContext,
//   audioArrayBuffer: ArrayBuffer,
//   resolve: (value: number | PromiseLike<number>) => void,
//   reject: (reason?: any) => void
// ) {
//   audioContext.decodeAudioData(audioArrayBuffer, (audioBuffer) => {
//     resolve(audioBuffer.duration);
//   }, (error) => {
//     reject(new Error('Failed to decode audio data'));
//   });
// }

export function getPrevNextText<T>(arr: any[], index: number) {
  if (index < 0 || index >= arr.length) {
      throw new Error("Index out of bounds");
  }

  const prevText = arr.slice(0, index).map(e => e?.voiceOver).join(" ");
  const currentText = arr[index]?.voiceOver;
  const nextText = arr.slice(index + 1).map(e => e.voiceOver).join(" ");

  return {
      prevText,
      currentText,
      nextText
  };
}


export async function aiTextToSoundEffect(
  text: string,
  duration_seconds:number,
 
) {
  try {
    let domainId
    let token
    if (IS_FREE_PLATFORM === 'false') {
      domainId = getDomainID()
      token = getToken()
    }
    const localEndPoint = axios.create({
      baseURL: `${NODE_BACKEND_URL}`,
    })
    const aiSoundEffectGenerationBody = {
      text: text,
      duration_seconds:duration_seconds,
      domainId: domainId,
      token: token,
      isFree: IS_FREE_PLATFORM,
      platform: NODE_ENV,
    }
   
    const aiTextToSoundEffectResponse = await localEndPoint.post('/api/v1/ai/sound-effect', aiSoundEffectGenerationBody)

    return aiTextToSoundEffectResponse.data
  } catch (error) {
    console.error('Error getting eleven labs audio:', error)
  }
}

