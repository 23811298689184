
import React from 'react';
import { AbsoluteFill, spring, useCurrentFrame, useVideoConfig } from 'remotion';

interface Word {
  word: string;
  start: number;
  end: number;
}

interface SubtitlesProp {
  captions: Word[];
  width: number;
  height: number;
  isDownload: boolean;
  style: any;
}

export const AnimatedSubtitles: React.FC<SubtitlesProp> = ({ captions, width, height, isDownload, style }) => {
  const frame = useCurrentFrame();
  const { fps } = useVideoConfig();

  if (captions === undefined) {
    return <></>;
  }
  const currentTime = frame / fps; // Current time in seconds

  const lastIndex = captions.findIndex((last) => last.end * fps >= frame) ?? -1;
  
  if (lastIndex === -1) return <></>;

  const start = Math.floor(lastIndex / style.wordsPerLine);

  const visibleCaptions = captions.slice(start * style.wordsPerLine, (start + 1) * style.wordsPerLine);

  const currentFrame = frame - (visibleCaptions?.[0].start || 0) * fps;
  const scale = spring({
    fps,
    frame,
  });

  const animStyle = {
    ...style,
    left: '50%',
    maxWidth: '90%',
    padding: '5px',
    zIndex: 100,
    position: isDownload ? style.position - 300 : style.position
  };

  const scaleFactor = isDownload ? 5 : Math.min(width / 1 / width, height / 1 / height);

  const textShadowSize = style.outlineSize ? `${style.outlineSize}px` : '0px';
  const textShadowColor = style.outlineColor || 'transparent';
  const textShadow = `${textShadowSize} ${textShadowColor}`;

  const adjustedYPosition = style.position;


  const injectKeyframes = (keyframes: string) => {
    const styleSheet = document.styleSheets[0] as CSSStyleSheet;
    styleSheet.insertRule(keyframes, styleSheet.cssRules.length);
  };

  // Inject keyframes when the module is loaded
 
    injectKeyframes(`
      @keyframes falling {
        0% {
          transform: translateY(-100%);
          opacity: 0;
        }
        50% {
          transform: translateY(10%);
          opacity: 1;
        }
        100% {
          transform: translateY(0);
          opacity: 1;
        }
      }
    `);

    injectKeyframes(`
      @keyframes scaleOut {
         0% {
          transform: scale(1); 
        50% {
          transform: scale(1.2); 
        }
        100% {
          transform: scale(1); 
        }
      }
    `);

    injectKeyframes(`
      @keyframes pulse {
        0% {
          transform: scale(1);
          opacity: 1;
        }
        50% {
          transform: scale(1.1);
          opacity: 0.7;
        }
        100% {
          transform: scale(1);
          opacity: 1;
        }
      }
    `);

    injectKeyframes(`
      @keyframes slide-up {
    from {
      transform: translateY(100%);
      opacity: 0;
    }
    to {
      transform: translateY(0);
      opacity: 1;
    }
  }
      `)
 
  return (
    <AbsoluteFill>
      <p
        style={{
          position: 'absolute',
          left: '50%',
          transform: `translateX(-50%) ${isDownload ? '' : `scale(${scaleFactor})`} rotate(${style.rotateX}deg)`,
          // transform: `translateX(-50%) ${isDownload ? '' : `scale(${scaleFactor})`} rotate(${rotationArr[randomIndex]}deg`,
          top: `${adjustedYPosition}px`,
          padding: '10px',
          backgroundColor: style.backgroundColor ?? 'black',
          borderRadius:style.borderRadius,
          color: style.fontColor ?? 'white',
          textAlign: 'center',
          fontFamily: style.font ?? 'Arial',
          fontSize: `${style.fontSize}px`,
          textShadow: textShadow,
          maxWidth: '100%',
          width: style?.whiteSpace ?'auto':'80%',
          lineHeight: '1',
          wordSpacing: "1px",
          textTransform: 'uppercase',
          display: style?.whiteSpace && 'inline-block',
          whiteSpace:style?.whiteSpace ?? '',
          boxSizing: style?.whiteSpace && 'border-box',
        }}
      >
        {visibleCaptions?.map((subtitle, index) => {
          const isCurrent = currentTime >= subtitle.start && currentTime <= subtitle.end;
          const isNext = currentTime <= subtitle.end;

          return (
            <span
              key={index}
              style={{
                ...animStyle,
                color: isCurrent ? style.highlightTextStyleColor ?? 'yellow' : style.fontColor ?? 'white',
                marginRight: '5px',
                border: isCurrent ? style.highlightTextborder ?? '' : '',
                borderRadius: isCurrent ? style.highlightTextborderRadius ?? '' : '',
                background: isCurrent ? style.highlightTextBackground ?? '' : '',
                display: style.nextWordMystery && (!isCurrent && isNext) ? 'none' : '',
                textTransform: 'uppercase',
                width: isCurrent ? '100px' : 'auto', // Set width for background
                animation: style.animation && isCurrent ? style.animationEase  : '',
                // animation:'slide-up 0.2s '
              }}
            >
              {subtitle.word}{' '}
            </span>
          );
        })}
      </p>
    </AbsoluteFill>
  );
};

function yPositionAllignmentForVideo(value: number | undefined, playerHeight: number, jsonDataHeight: number) {
  const oldValue = value ?? 50 // Assuming this is the Y position in the original scale

  const oldMin = 0
  const oldMax = jsonDataHeight

  // Define new range
  const newMin = 0
  const newMax = playerHeight

  // Apply formula to scale oldValue from the old range to the new range
  const newValue = Math.round(((oldValue - oldMin) * (newMax - newMin)) / (oldMax - oldMin) + newMin)

  return newValue
}
