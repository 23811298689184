import { ScriptToCanvasBurst } from "./BurstScriptToCanvas";
import { ScriptToCanvas } from "./ScriptToCanvas";
import { RandomScriptToCanvas } from "./RandomScriptToCanvas";
import { hexToRgb } from "../polotnoStore";
import { useGlobalContext } from "../../../context/globalContext";
import { PromptScriptToCanvas } from "./PromptScriptToCanvas";
import { AvatarScriptToCanvas } from "./avatarFunctions/AvatarScriptToCanvasNormal";
import { ScriptToCanvasBurstVersion2 } from "./version_2/ScriptToCanvas";
export const ScriptToCanvasFunc = async (
  setPageLoading: any,
  scriptDetails: any,
  firstRender: boolean,
  industry: string,
  setBackgroundMusic: any,
  setScriptDetails: any,
  setProgressBarPercent: any,
  icon: string,
  selectedSize: string,
  videoStyle: string,
  videoMediaType: string,
  store: any,
  color: any,
  userDetails: any,
  setShowTextOverlay: any,
  avatarTasks: any,
  setAvatarTasks: any,
  setSnapShotsTasks: any
) => {
  if (firstRender === true) {
    if (!userDetails.purpose?.promptToVideo) {
      await ScriptToCanvasBurstVersion2(
        store,
        scriptDetails,
        industry,
        setBackgroundMusic,
        setScriptDetails,
        setProgressBarPercent,
        icon,
        selectedSize,
        videoStyle,
        videoMediaType,
        hexToRgb(color),
        userDetails,
        setSnapShotsTasks
      );
    } else {
      await ScriptToCanvasBurstVersion2(
        store,
        scriptDetails,
        industry,
        setBackgroundMusic,
        setScriptDetails,
        setProgressBarPercent,
        icon,
        selectedSize,
        videoStyle,
        videoMediaType,
        hexToRgb(color),
        userDetails,
        setSnapShotsTasks
      );
    }
    setPageLoading(false);
  }
};
