export const FONT_TEMPLATE = [
  {
    name: 'Box-highlight',
    url: '/fonts/boxhighlight.png',
    font: 'THE BOLD FONT',
    capitalizationReq: true,
    wordsPerLine: 3,
    backgroundColor: '',
    fontColor: '#FFFFFF',
    highlightTextStyleColor: '#FFFFFF',
    fontSize: 30,
    position: 200,
    rotateX: 0,
    shadow: 'no',
    strokeWidth: 0,
    stroke: '',
    textShadow: '',
    WebkitTextStroke: '',
    border: 'none',
    borderRadius:'',
    background:'',
    nextWordMystery:false,
    highlightTextborder:'',
    highlightTextborderRadius:'8px',
    highlightTextBackground:'#7036d6',
    animation:true,
    fontWeight: '900',
    lineHeight: '1.5',
    animationEase:'pulse 1s ease-out',
    whiteSpace:''
  },
  {
    name: 'Grape',
    url: '/fonts/grape.png',
    font: 'THE BOLD FONT',
    capitalizationReq: true,
    wordsPerLine: 2,
    backgroundColor: '#FFFFFF',
    fontColor: 'black',
    highlightTextStyleColor: '#7036d6',
    fontSize: 30,
    position: 200,
    rotateX: 0,
    shadow: 'no',
    strokeWidth: 0,
    stroke: '',
    textShadow: '',
    WebkitTextStroke: '',
    border: '1px white',
    borderRadius:'8px',
    background:'',
    nextWordMystery:false,
    highlightTextborder:'',
    highlightTextborderRadius:'',
    highlightTextBackground:'',
    animation:true,
    fontWeight: '900',
    lineHeight: '1.2',
    animationEase:'',
    whiteSpace:'nowrap',

  },
  
  {
    name: 'Next-word-mystery',
    url: '/fonts/nextword.png',
    font: 'Poppins',
    capitalizationReq: true,
    wordsPerLine: 3,
    backgroundColor: '',
    fontColor: '#FFFFFF',
    highlightTextStyleColor: '#b6ff60',
    fontSize: 30,
    position: 200,
    rotateX: 0,
    shadow: 'no',
    strokeWidth: 0,
    stroke: '',
    textShadow: '4px 2px 4px black',
    WebkitTextStroke: '',
    border: 'none',
    borderRadius:'',
    background:'',
    nextWordMystery:true,
    highlightTextborder:'',
    highlightTextborderRadius:'',
    highlightTextBackground:'',
    animation:true,
    fontWeight: '900',
    lineHeight: '1.2',
    animationEase:'',
    whiteSpace:''

  },
  
  {
    name: 'Slant-cap',
    url: '/fonts/slang.png',
    font: 'THE BOLD FONT',
    capitalizationReq: true,
    wordsPerLine: 3,
    backgroundColor: '',
    fontColor: '#FFFFFF',
    highlightTextStyleColor: '#FFFFFF',
    // outlineColor: '#000000',
    // outlineSize: 10,
    fontSize: 30,
    position: 200,
    rotateX: 20,
    shadow: 'no',
    strokeWidth: 0,
    stroke: '',
    textShadow: `
    -2px -2px 0 black,
        2px -2px 0 black,
        -2px 2px 0 black,
        2px 2px 0 black,
        -2px 0 0 black,
        2px 0 0 black,
        0 -2px 0 black,
        0 2px 0 black,
        -4px -4px 0 black,
        4px -4px 0 black,
        -4px 4px 0 black,
        4px 4px 0 black,
        -4px 0 0 black,
        4px 0 0 black,
        0 -4px 0 black,
        0 4px 0 black
    `,
    WebkitTextStroke: '',
    // border: '2px solid black', /* Border width and color */
    // borderRadius: '10px',
    // background: 'red',
    border: 'none',
    borderRadius:'',
    background:'',
    nextWordMystery:false,
    highlightTextborder:'',
    highlightTextborderRadius:'',
    highlightTextBackground:'',
    animation:true,
    fontWeight: '900',
    lineHeight: '1.2',
    animationEase:'falling 1s ease-out',
     whiteSpace:''
  },
  {
    name: 'Single-Word-Style',
    url: '/fonts/single-word-style.png',
    font: 'Konga next',
    capitalizationReq: true,
    wordsPerLine: 1,
    backgroundColor: '',
    fontColor: '#FFFFFF',
    highlightTextStyleColor: '#FFE400',
    // outlineColor: '#000000',
    // outlineSize: 10,
    fontSize: 30,
    position: 200,
    rotateX: 0,
    shadow: 'no',
    strokeWidth: 2,
    stroke: 'black',
    textShadow: '-1px -1px 0 black, 1px -1px 0 black, -1px 1px 0 black, 1px 1px 0 black',
    WebkitTextStroke: '2px black',
    // border: '2px solid black', /* Border width and color */
    // borderRadius: '10px',
    // background: 'red',
    border: 'none',
    borderRadius:'',
    background:'',
    nextWordMystery:false,
    highlightTextborder:'',
    highlightTextborderRadius:'',
    highlightTextBackground:'',
    fontWeight: '110',
    animation:true,
    lineHeight: '1.2',
     whiteSpace:''
  },
  // {
  //   name: 'Single-Word-Style-2',
  //   url: '/fonts/single-word-style.png',
  //   font: 'Konga next',
  //   capitalizationReq: true,
  //   wordsPerLine: 1,
  //   backgroundColor: '',
  //   fontColor: '#FFFFFF',
  //   highlightTextStyleColor: '#FFE400',
  //   // outlineColor: '#000000',
  //   // outlineSize: 10,
  //   fontSize: 30,
  //   position: 200,
  //   rotateX: 0,
  //   shadow: 'no',
  //   strokeWidth: '',
  //   stroke: '',
  //   textShadow: '',
  //   WebkitTextStroke: '',
  //   // border: '2px solid black', /* Border width and color */
  //   // borderRadius: '10px',
  //   // background: 'red',
  //   border: 'none',
  //   borderRadius:'',
  //   background:'',
  //   nextWordMystery:false,
  //   highlightTextborder:'',
  //   highlightTextborderRadius:'',
  //   highlightTextBackground:'',
  //   fontWeight: '110',
  //   animation:false,
  //   lineHeight: '1.2',
  //    whiteSpace:''
  // },
  {
    name: 'Single-Word-Style-2',
    url: '/fonts/single-word-style-2.png',
    font: 'Poppins',
    captilazationReq: true,
    wordsPerLine: 1,
    backgroundColor: '',
    fontColor: '#FFFFFF',
    highlightTextStyleColor: '#FFE400',
    outlineColor: '#000000',
    outlineSize: 10,
    fontSize: 27,
    position: 50,
    rotateX: -10,
    shadow: 'no',
    strokeWidth: 0,
    stroke: 'black',
    textShadow: '',
    WebkitTextStroke: '',
    border: '', /* Border width and color */
    borderRadius: '',
    background: '',
    nextWordMystery:false,
    highlightTextborder:'',
    highlightTextborderRadius:'',
    highlightTextBackground:'',
    fontWeight: '110',
    animation:false,
    lineHeight: '1.2',
     whiteSpace:''
  },
  {
    name: 'Bold-Street-Video-Style',
    url: '/fonts/bold-street-video-style.png',
    font: 'Poppins',
    capitalizationReq: true,
    wordsPerLine: 3,
    backgroundColor: '#091300',
    fontColor: '#F8E71C',
    highlightTextStyleColor: '#B8E986',
    outlineColor: '#000000',
    outlineSize: 8,
    fontSize: 30,
    position: 65,
    rotateX: -10,
    shadow: 'no',
    strokeWidth: 0,
    stroke: 'black',
    textShadow: '',
    WebkitTextStroke: '',
    border: '', /* Border width and color */
    borderRadius: '',
    background: '',
    nextWordMystery:false,
    highlightTextborder:'',
    highlightTextborderRadius:'',
    highlightTextBackground:'',
    fontWeight: '110',
    animation:false,
    lineHeight: '1.2',
     whiteSpace:''
  },
  {
    name: 'Podcast-Attention-Grabber-Style',
    url: '/fonts/podcast-attention-grabber-style.png',
    font: 'Konga next',
    capitalizationReq: true,
    wordsPerLine: 5,
    backgroundColor: '#FFFCE2',
    fontColor: '#000000',
    highlightTextStyleColor: '#BD10E0',
    outlineColor: '#000000',
    outlineSize: 0,
    fontSize: 30,
    position: 78,
    rotateX: -10,
    shadow: 'no',
    strokeWidth: 0,
    stroke: 'black',
    textShadow: '',
    WebkitTextStroke: '',
    border: '', /* Border width and color */
    borderRadius: '',
    background: '',
    nextWordMystery:false,
    highlightTextborder:'',
    highlightTextborderRadius:'',
    highlightTextBackground:'',
    fontWeight: '110',
    animation:false,
    lineHeight: '1.2',
     whiteSpace:''
  },
  {
    name: 'The-Hormozin-Style-1',
    url: '/fonts/the-hormozi-style-1.png',
    capitalizationReq: true,
    font: 'Konga next',
    wordsPerLine: 3,
    backgroundColor: '',
    fontColor: '#FFFFFF',
    highlightTextStyleColor: '#FDFF05',
    outlineColor: '#000000',
    outlineSize: 10,
    fontSize: 30,
    position: 45,
    rotateX: 0,
    shadow: 'needed',
    strokeWidth: 0,
    stroke: 'black',
    textShadow: '',
    WebkitTextStroke: '',
    border: '', /* Border width and color */
    borderRadius: '',
    background: '',
    nextWordMystery:false,
    highlightTextborder:'',
    highlightTextborderRadius:'',
    highlightTextBackground:'',
    fontWeight: '110',
    animation:false,
    lineHeight: '1.2',
     whiteSpace:''
  },
  {
    name: 'The-Hormozin-Style-2',
    url: '/fonts/the-hormozi-style-2.png',
    capitalizationReq: 10,
    font: 'Konga next',
    wordsPerLine: 3,
    backgroundColor: '',
    fontColor: '#FFFFFF',
    highlightTextStyleColor: '#00FF11',
    outlineColor: '#000000',
    outlineSize: 10,
    fontSize: 30,
    position: 45,
    rotateX: 0,
    shadow: 'needed',
    strokeWidth: 0,
    stroke: 'black',
    textShadow: '',
    WebkitTextStroke: '',
    border: '', /* Border width and color */
    borderRadius: '',
    background: '',
    nextWordMystery:false,
    highlightTextborder:'',
    highlightTextborderRadius:'',
    highlightTextBackground:'',
    fontWeight: '110',
    animation:false,
    lineHeight: '1.2',
     whiteSpace:''
  },
  {
    name: 'The-Hormozin-Style-3',
    url: '/fonts/the-hormozi-style-3.png',
    font: 'Konga next',
    capitalizationReq: 10,
    wordsPerLine: 3,
    backgroundColor: '',
    fontColor: '#FFFFFF',
    highlightTextStyleColor: '#FDFF05',
    outlineColor: '#000000',
    outlineSize: 10,
    fontSize: 30,
    position: 45,
    rotateX: -10,
    shadow: 'needed',
    strokeWidth: 0,
    stroke: 'black',
    textShadow: '',
    WebkitTextStroke: '',
    border: '', /* Border width and color */
    borderRadius: '',
    background: '',
    nextWordMystery:false,
    highlightTextborder:'',
    highlightTextborderRadius:'',
    highlightTextBackground:'',
    fontWeight: '110',
    animation:false,
    lineHeight: '1.2',
     whiteSpace:''
  },
  {
    name: 'The-Vee-Friends-Style-1',
    url: '/fonts/the-vee-friends-style-1.png',
    font: 'Konga next',
    capitalizationReq: true,
    wordsPerLine: 7,
    backgroundColor: '',
    fontColor: '#FFFFFF',
    highlightTextStyleColor: '#FFE400',
    outlineColor: '#000000',
    outlineSize: 8,
    fontSize: 30,
    position: 45,
    rotateX: 0,
    strokeWidth: 0,
    stroke: 'black',
    textShadow: '',
    WebkitTextStroke: '',
    border: '', /* Border width and color */
    borderRadius: '',
    background: '',
    nextWordMystery:false,
    highlightTextborder:'',
    highlightTextborderRadius:'',
    highlightTextBackground:'',
    fontWeight: '110',
    animation:false,
    lineHeight: '1.2',
     whiteSpace:''
  },
  {
    name: 'Viral-Finance-Tiktok-Style',
    url: '/fonts/viral-finance-tiktok-style.png',
    font: 'Konga next',
    capitalizationReq: true,
    wordsPerLine: 3,
    backgroundColor: '',
    fontColor: '#FFFFFF',
    highlightTextStyleColor: '#92FC27',
    outlineColor: '#000000',
    outlineSize: 10,
    fontSize: 30,
    position: 50,
    rotateX: -10,
    shadow: 'no',
    strokeWidth: 0,
    stroke: 'black',
    textShadow: '',
    WebkitTextStroke: '',
    border: '', /* Border width and color */
    borderRadius: '',
    background: '',
    nextWordMystery:false,
    highlightTextborder:'',
    highlightTextborderRadius:'',
    highlightTextBackground:'',
    fontWeight: '110',
    animation:false,
    lineHeight: '1.2',
     whiteSpace:''
  },
]
