import axios from "axios";
// import { Easing, interpolate, prefetch, spring } from "remotion";
import { IS_FREE_PLATFORM, NODE_BACKEND_URL } from "../../config";
import { Easing, interpolate, prefetch, spring, type PrefetchOnProgress } from "remotion";

import {
  easeInCubis,
  easeInOutBounce,
  easeOutCirc,
  easeOutElastic,
  easingFunctionOne,
  easeOutExpo,
  easeInOutCubic,
  easeInOutQuad,
  easeOutQuad,
} from "./VideoGeneration/helperFunctions/easingFunctions";
// import type {PrefetchOnProgress} from 'remotion';
// import { easeOutExpo, easeInOutCubic } from 'd3-ease';
// This function will take the duration of the gif and the frames we want the gif to play at and will return the duration for the remotion player
export function millisecondsToFrames(
  milliseconds: number,
  fps: number
): number {
  const seconds = milliseconds / 1000; // Convert milliseconds to seconds
  return Math.round(seconds * fps); // Convert seconds to frames and round to nearest whole number
}

export async function downloadMp4(
  data: any,
  duration: number,
  width: number,
  height: number,
  jsonData: any,
  rotation: number,
  translateY: number,
  translateX: number,
  transitionDurationInFrames: number,
  transitionAnimationProp: any,
  backgroundMusicUrl: string,
  backgroundMusicDuration: number,
  isDownload: boolean,
  setIsMp4Downloading: (value: boolean) => void
) {
  axios
    .post(`${NODE_BACKEND_URL}/api/v1/render`, {
      data,
      jsonData,
      width,
      height,
      rotation,
      translateY,
      translateX,
      transitionDurationInFrames,
      transitionAnimationProp,
      backgroundMusicUrl,
      backgroundMusicDuration,
      isDownload,
    })
    .then((response) => {
      const videoUrl = response.data; // Assuming response.data contains the URL of the video
      // Fetch the video from the URL
      axios
        .get(videoUrl, {
          responseType: "blob", // This tells Axios to handle the response as a Blob
          headers: {
            Origin: "https://rayn.group", // This header might not be necessary in client-side requests
          },
        })
        .then((response) => {
          // The blob is now directly available as response.data
          const blob = response.data;

          // Create a local URL for the blob
          const localUrl = window.URL.createObjectURL(blob);

          // Create a temporary link element
          const link = document.createElement("a");
          link.href = localUrl;
          link.setAttribute("download", "video.mp4"); // Set the filename for the download

          // Append the link to the document
          document.body.appendChild(link);

          // Programmatically click the link to trigger the download
          link.click();

          setIsMp4Downloading(false);

          // Optionally, remove the link after triggering the download
          // This cleanup step might be delayed or managed differently depending on your app's needs
          setTimeout(() => {
            window.URL.revokeObjectURL(localUrl); // Release the created object URL
            // link.parentNode.removeChild(link);
          }, 100); // Short delay to ensure the download process starts
        })
        .catch((error) => {
          console.error("Error downloading the video: ", error);
          setIsMp4Downloading(false);
        });
    })
    .catch((error) => {
      console.log(error);
      setIsMp4Downloading(false);
    });
}

export const applyAnimations = (
  pageRef: any,
  pageData: { children: { id: string; animations: any[] }[] },
  frame: number,
  fps: number,
  durationInFrames: number,
  width: number,
  height: number
) => {
  if (pageRef.current) {
    pageData.children.forEach((child: { id: string; animations: any[] }) => {
      const cssSafeId = CSS.escape(child.id);

      const element = pageRef.current?.querySelector(`#${cssSafeId}`);

      if (element) {
        let isCompleted = false;

        const enterAnimation = child.animations.find((a) => a.type === "enter");
        // const exitAnimation = child.animations.find((a) => a.type === 'exit')

        const enterAnimationFalse = child.animations.find(
          (a) => a.type === "enter" && a.enabled === false
        );
        const exitAnimationFalse = child.animations.find(
          (a) => a.type === "exit" && a.enabled === false
        );

        const startFrame =
          ((enterAnimation?.delay === 0 ? 0.01 : enterAnimation?.delay) /
            1000) *
          fps;
        const endFrameEnter =
          startFrame +
          ((enterAnimation?.duration === 0 ? 0.01 : enterAnimation?.duration) /
            1000) *
            fps;
        const easingFunction =
          IS_FREE_PLATFORM === "true"
            ? Easing.bezier(0.12, 0.53, 0.26, 0.9)
            : undefined;
        if (frame > endFrameEnter) {
          isCompleted = true;
        }

        child.animations.forEach((animationL) => {
          const animation = { ...animationL };
                    // animation.name = 'rotate'
          if (animation.enabled && element instanceof HTMLElement) {
            let elementAnimationForFadeAndZoom = 150;
            let elementAnimationPositvieX = 1;
            let elementAnimationNegativeX = 1;
            let elementAnimationDiagonal = 1;
            let rotateElement = 1;

            if (
              animation.enabled === true &&
              animation.type === "enter" &&
              exitAnimationFalse
            ) {
              const initialPositiveOffsetX = -25;
              const initialNegativeOffsetX = 25;
              const initialDiagonalOffsetY = 25;
              // const transform = makeTransform([rotate(45), translate(50, 50)])
              // move forward in range to alternate between 10 and -10 on
              // each quarter of the animation

              const startFrame =
                ((animation?.delay === 0 ? 0.01 : animation?.delay) / 1000) *
                fps;
              const endFrame =
                startFrame +
                ((animation.duration === 0 ? 0.01 : animation.duration) /
                  1000) *
                  fps;
              const rotateStart = frame < endFrame / 2 ? 10 : -10;
              const rotateEnd = frame < endFrame / 2 ? -10 : 10;

              elementAnimationForFadeAndZoom = interpolate(
                frame,
                [startFrame, endFrame],
                [1, 1.4],
                {
                  extrapolateLeft: "clamp",
                  extrapolateRight: "clamp",
                  // easing: easingFunction,
                }
              );
              elementAnimationPositvieX = interpolate(
                frame,
                [startFrame, endFrame],
                [initialPositiveOffsetX, 0],
                {
                  extrapolateLeft: "clamp",
                  extrapolateRight: "clamp",
                  // easing: easingFunction,
                }
              );

              elementAnimationNegativeX = interpolate(
                frame,
                [startFrame, endFrame],
                [initialNegativeOffsetX, 0],
                {
                  extrapolateLeft: "clamp",
                  extrapolateRight: "clamp",
                  // easing: easingFunction,
                }
              );

              elementAnimationDiagonal = interpolate(
                frame,
                [startFrame, endFrame],
                [initialDiagonalOffsetY, 0],
                {
                  extrapolateLeft: "clamp",
                  extrapolateRight: "clamp",
                  // easing: easingFunction,
                }
              );
              rotateElement = interpolate(
                frame,
                [startFrame, endFrame],
                [rotateStart, rotateEnd],
                {
                  extrapolateLeft: "clamp",
                  extrapolateRight: "clamp",
                  // easing: easingFunction,
                }
              );
            }

            if (
              animation.enabled === true &&
              animation.type === "exit" &&
              enterAnimationFalse
            ) {
              if (isCompleted === true) {
                const initialPositiveOffsetX = 25;
                const initialNegativeOffsetX = -25;
                const initialDiagonalOffsetY = -25;

                const startFrame = durationInFrames - endFrameEnter;
                const endFrame = durationInFrames;

                elementAnimationForFadeAndZoom = interpolate(
                  frame,
                  [startFrame, endFrame],
                  [1, 0],
                  {
                    extrapolateLeft: "clamp",
                    extrapolateRight: "clamp",
                    // easing: easingFunction,
                  }
                );

                elementAnimationPositvieX = interpolate(
                  frame,
                  [startFrame, endFrame],
                  [1, initialPositiveOffsetX],
                  {
                    extrapolateLeft: "clamp",
                    extrapolateRight: "clamp",
                    // easing: easingFunction,
                  }
                );

                elementAnimationNegativeX = interpolate(
                  frame,
                  [startFrame, endFrame],
                  [1, initialNegativeOffsetX],
                  {
                    extrapolateLeft: "clamp",
                    extrapolateRight: "clamp",
                    // easing: easingFunction,
                  }
                );

                elementAnimationDiagonal = interpolate(
                  frame,
                  [startFrame, endFrame],
                  [1, initialDiagonalOffsetY],
                  {
                    extrapolateLeft: "clamp",
                    extrapolateRight: "clamp",
                    // easing: easingFunction,
                  }
                );
              }
            }

            if (!enterAnimationFalse && !exitAnimationFalse) {
              let isCompleted = false;

              // const initialPositiveOffsetX =  -20
              // const initialNegativeOffsetX =  20
              // const initialDiagonalOffsetY =  20
              const initialPositiveOffsetX = -25;
              const initialNegativeOffsetX = 25;
              const initialDiagonalOffsetY = 25;
              const startFrameEnter =
                ((animation?.delay === 0 ? 0.01 : animation?.delay) / 1000) *
                fps;
              const endFrameEnter =
                startFrameEnter +
                ((animation?.duration === 0 ? 0.01 : animation?.duration) /
                  1000) *
                  fps;

              const startFrameExit = durationInFrames - endFrameEnter;
              const endFrame = durationInFrames;

              if (frame > endFrameEnter) {
                isCompleted = true;
              }

              elementAnimationForFadeAndZoom = interpolate(
                frame,
                [startFrameEnter, endFrameEnter],
                [0, 1],
                {
                  extrapolateLeft: "clamp",
                  extrapolateRight: "clamp",
                }
              );

              elementAnimationPositvieX = interpolate(
                frame,
                [startFrameEnter, endFrameEnter],
                [initialPositiveOffsetX, 0],
                {
                  extrapolateLeft: "clamp",
                  extrapolateRight: "clamp",
                  // easing: easingFunction,
                }
              );

              elementAnimationNegativeX = interpolate(
                frame,
                [startFrameEnter, endFrameEnter],
                [initialNegativeOffsetX, 0],
                {
                  extrapolateLeft: "clamp",
                  extrapolateRight: "clamp",
                  // easing: easingFunction,
                }
              );

              elementAnimationDiagonal = interpolate(
                frame,
                [startFrameEnter, endFrameEnter],
                [initialDiagonalOffsetY, 0],
                {
                  extrapolateLeft: "clamp",
                  extrapolateRight: "clamp",
                  // easing: easingFunction,
                }
              );

              if (isCompleted === true) {
                const initialPositiveOffsetX = 25;
                const initialNegativeOffsetX = -25;
                const initialDiagonalOffsetY = -25;

                elementAnimationForFadeAndZoom = interpolate(
                  frame,
                  [startFrameExit, endFrame],
                  [1, 0],
                  {
                    extrapolateLeft: "clamp",
                    extrapolateRight: "clamp",
                    // easing: easingFunction,
                  }
                );

                elementAnimationPositvieX = interpolate(
                  frame,
                  [startFrameExit, endFrame],
                  [1, initialPositiveOffsetX],
                  {
                    extrapolateLeft: "clamp",
                    extrapolateRight: "clamp",
                    // easing: easingFunction,
                  }
                );

                elementAnimationNegativeX = interpolate(
                  frame,
                  [startFrameExit, endFrame],
                  [1, initialNegativeOffsetX],
                  {
                    extrapolateLeft: "clamp",
                    extrapolateRight: "clamp",
                    // easing: easingFunction,
                  }
                );

                elementAnimationDiagonal = interpolate(
                  frame,
                  [startFrameExit, endFrame],
                  [1, initialDiagonalOffsetY],
                  {
                    extrapolateLeft: "clamp",
                    extrapolateRight: "clamp",
                    // easing: easingFunction,
                  }
                );
              }
            }
       
            if (animation.name === "fade") {
              element.style.opacity = `${elementAnimationForFadeAndZoom}`;
            } 
            else {
            
              if (animation.name === "zoom") {
                element.style.transformOrigin = "center center";
                element.style.scale = `${elementAnimationForFadeAndZoom}`;
              } else if (animation.name === "move") {
                if (animation.data.direction === "right") {
                  // element.style.opacity = `${elementAnimationForFadeAndZoom}`
                  element.style.transform = `translateX(${elementAnimationPositvieX}px)`;
                }
                if (animation.data.direction === "left") {
                  // element.style.opacity = `${elementAnimationForFadeAndZoom}`
                  element.style.transform = `translateX(${elementAnimationNegativeX}px)`;
                }
                if (animation.data.direction === "up") {
                  // element.style.opacity = `${elementAnimationForFadeAndZoom}`
                  element.style.transform = `translateY(${-elementAnimationPositvieX}px)`;
                }
                if (animation.data.direction === "down") {
                  // element.style.opacity = `${elementAnimationForFadeAndZoom}`
                  element.style.transform = `translateY(${-elementAnimationNegativeX}px)`;
                }
                if (animation.data.direction === "bottom-right") {
                  // element.style.opacity = `${elementAnimationForFadeAndZoom}`
                  element.style.transform = `translate(${elementAnimationPositvieX}px, ${-elementAnimationNegativeX}px)`;
                }
                if (animation.data.direction === "bottom-left") {
                  // element.style.opacity = `${elementAnimationForFadeAndZoom}`
                  element.style.transform = `translate(${-elementAnimationPositvieX}px, ${-elementAnimationNegativeX}px)`;
                }
                if (animation.data.direction === "top-right") {
                  // element.style.opacity = `${elementAnimationForFadeAndZoom}`
                  element.style.transform = `translate(${-elementAnimationDiagonal}px, ${elementAnimationNegativeX}px)`;
                }
                if (animation.data.direction === "top-left") {
                  // element.style.opacity = `${elementAnimationForFadeAndZoom}`
                  element.style.transform = `translate(${-elementAnimationPositvieX}px, ${elementAnimationDiagonal}px )`;
                }

                // console.log(animation.data.direction)
                if (animation.data.direction === "3d-perspective") {
                  // if (animation.data.direction === 'move-zoom') {
                  const scaleFactor = Math.min(
                    width / 1 / width,
                    height / 1 / height
                  );
                  const rotationX = Math.sin(frame / 50) * 10; // Rotate X based on frame
                  const rotationY = Math.cos(frame / 50) * 10; // Rotate Y based on frame
                  const translateZ = 150; // Translate the element in Z-axis
                  const perspectiveDistance = 1000; // Perspective depth
                  element.style.transformOrigin = "center center";
                  element.style.transform = `perspective(${perspectiveDistance}px) rotateX(${rotationX}deg) rotateY(${rotationY}deg) translateZ(${translateZ}px) scale(${scaleFactor})`;
                }
                if (animation.data.direction === "move-zoom") {
                  const zoomDuration = endFrameEnter / 2; // Duration for the zooming phases

                  element.style.transformOrigin = "center center";

                  // Zoom in with elastic easing
                  const zoomIn = interpolate(
                    frame,
                    [startFrame, startFrame + zoomDuration],
                    [1, 1.4],
                    {
                      extrapolateLeft: "clamp",
                      extrapolateRight: "clamp",
                      easing: easeOutElastic,
                    }
                  );

                  // Zoom out with ease-out-circ easing
                  const zoomOut = interpolate(
                    frame,
                    [startFrame + zoomDuration, startFrame + 2 * zoomDuration],
                    [1.4, 1.0],
                    {
                      extrapolateLeft: "clamp",
                      extrapolateRight: "clamp",
                      // easing: easeOutCirc,
                    }
                  );
                  // element.style.transform = `scale(${scale})`
                  // Apply transformations based on the current frame
                  if (frame <= startFrame + zoomDuration) {
                    // Zooming in with elastic easing
                    element.style.transform = `scale(${zoomIn})`;
                  } else if (frame <= startFrame + 2 * zoomDuration) {
                    // Zooming out with ease-out-circ easing
                    element.style.transform = `scale(${zoomOut})`;
                  } else {
                    // Ensuring final scale value is maintained
                    element.style.transform = `scale(1)`;
                  }
                }
                if (animation.data.direction === "zoomIn-out") {
                  // Define the duration of each phase
                  const idleDuration = endFrameEnter / 4; // Idle time 25%
                  const zoomInDuration = endFrameEnter / 2; // Zoom in 50%
                  const zoomOutDuration =
                    endFrameEnter - idleDuration - zoomInDuration; // Rest for zoom out

                  // Set transform origin to center
                  element.style.transformOrigin = "center center";

                  // Easing function for smooth transitions
                  const easingFunction = (t: number) => t * (2 - t);

                  // Initial scale for idle
                  const idleScale = 1;

                  // Zoom in phase: from idle scale to slightly zoomed in
                  const zoomIn = interpolate(
                    frame,
                    [
                      startFrame + idleDuration,
                      startFrame + idleDuration + zoomInDuration,
                    ],
                    [idleScale, 1.5],
                    {
                      extrapolateLeft: "clamp",
                      extrapolateRight: "clamp",
                      easing: easeOutCirc,
                    }
                  );

                  // Zoom out phase: from zoomed in to slightly zoomed out
                  const zoomOut = interpolate(
                    frame,
                    [
                      startFrame + idleDuration + zoomInDuration,
                      startFrame +
                        idleDuration +
                        zoomInDuration +
                        zoomOutDuration,
                    ],
                    [1.5, 1.0],
                    {
                      extrapolateLeft: "clamp",
                      extrapolateRight: "clamp",
                      easing: easeInCubis,
                    }
                  );
                  // element.style.transform = `scale(${scale})`
                  // Apply transformations based on the current frame
                  if (frame <= startFrame + idleDuration) {
                    // Idle phase
                    element.style.transform = `scale(${idleScale})`;
                  } else if (
                    frame <=
                    startFrame + idleDuration + zoomInDuration
                  ) {
                    // Zoom in phase
                    element.style.transform = `scale(${zoomIn})`;
                    // element.style.transform = `scale(${scale})`;
                  } else {
                    // Zoom out phase
                    element.style.transform = `scale(${zoomOut})`;
                  }
                } 
               
                // Handle move animations
                if (animation.data.direction === "Wiggle") {
                  // const zoomDuration = endFrameEnter ; // Duration for the zoom-in phase (slow zoom)

                  // element.style.transformOrigin = "center center";
                
                  // // Zoom-in with smooth easing
                  // const zoomIn = interpolate(
                  //   frame,
                  //   [startFrame, startFrame + zoomDuration],
                  //   [1, 1.4], // Start at scale 1, zoom to 1.4
                  //   {
                  //     extrapolateLeft: "clamp",
                  //     extrapolateRight: "clamp",
                  //     easing: easeOutCirc, // Smooth easing for zoom-in
                  //   }
                  // );
                
                  // // Wiggle effect (oscillating movement)
                  // const wiggle = interpolate(
                  //   frame,
                  //   [startFrame, startFrame + zoomDuration], // Duration for wiggle matches zoom phase
                  //   [-5, 5], // Oscillation range (in pixels)
                  //   {
                  //     extrapolateLeft: "clamp",
                  //     extrapolateRight: "clamp",
                  //     easing: (t) => Math.sin(t * 10 * Math.PI), // High-frequency oscillation for wiggle
                  //   }
                  // );
                
                  // // Apply the combined zoom and wiggle transformations during zoom-in
                  // element.style.transform = `scale(${zoomIn}) translateX(${wiggle}px)`;
              
                  const zoomDuration = endFrameEnter; // Duration for the zoom-in phase (slow zoom)

                  // Set transform origin for scaling
                  element.style.transformOrigin = "center center";
                  
                  // Zoom-in with smooth easing
                  const zoomIn = interpolate(
                    frame,
                    [startFrame, startFrame + zoomDuration],
                    [1, 1.4], // Start at scale 1, zoom to 1.4
                    {
                      extrapolateLeft: "clamp",
                      extrapolateRight: "clamp",
                      easing: easeOutCirc, // Smooth easing for zoom-in
                    }
                  );
                  
                  // Wiggle effect (oscillating movement for both X and Y axes)
                  const wiggleX = interpolate(
                    frame,
                    [startFrame, startFrame + zoomDuration],
                    [-5, 5], // Oscillation range in pixels for X
                    {
                      extrapolateLeft: "clamp",
                      extrapolateRight: "clamp",
                      easing: (t) => Math.sin(t * 2 * Math.PI), // Lower frequency oscillation
                    }
                  );
                  
                  const wiggleY = interpolate(
                    frame,
                    [startFrame, startFrame + zoomDuration],
                    [-5, 5], // Oscillation range in pixels for Y
                    {
                      extrapolateLeft: "clamp",
                      extrapolateRight: "clamp",
                      easing: (t) => Math.sin(t * 2.5 * Math.PI), // Slightly different frequency for natural movement
                    }
                  );
                  
                  // Apply the combined zoom and wiggle transformations
                  element.style.transform = `scale(${zoomIn}) translate(${wiggleX}px, ${wiggleY}px)`;
                  
                }
              } else if (animation.name === "rotate") {
                element.style.transform = `rotate(${rotateElement}deg)`;
              }
            }
          }
        });
      }
    });
  }
};

export const prefetchAsset = async (
  url: string,
  contentType = "audio/mpeg"
) => {
  // console.log(url)
  if (url) {
 
    const { waitUntilDone } = prefetch(url, {
      method: "blob-url",
      contentType,
      onProgress
    });
  
 const response =  await  waitUntilDone();

  }
};

export function calculateTrimTimes(
  duration: number,
  startTimeFraction: number,
  endTimeFraction: number,
  fps: number
) {
  // Convert duration from milliseconds to seconds
  const durationInSeconds = duration / 1000;

  // Calculate start and end times in seconds
  const startTimeInSeconds = durationInSeconds * startTimeFraction;
  const endTimeInSeconds = durationInSeconds * endTimeFraction;

  // Convert seconds to frames
  const startTimeFrames = Math.round(startTimeInSeconds * fps);
  const endTimeFrames = Math.round(endTimeInSeconds * fps);

  // Ensure the start time does not exceed end time and both are within the video duration
  const validStartTime = Math.max(0, Math.min(startTimeFrames, endTimeFrames));
  const validEndTime = Math.min(
    Math.round(durationInSeconds * fps),
    endTimeFrames
  );

  return {
    startTime: startTimeFrames,
    endTime: validEndTime,
  };
}

const applyZoomAndWiggle = (element: any) => {
  let startTime = performance.now();

  const animate = (currentTime: any) => {
    const elapsedTime = currentTime - startTime;

    // Zoom-in effect: Gradually increase scale
    const zoomScale = 1 + 0.5 * Math.min(elapsedTime / 2000, 1); // Caps at 1.5x zoom over 2 seconds

    // Wiggle effect: Oscillate horizontally
    const frequency = 5; // Number of wiggles per second
    const amplitude = 0.05; // Intensity of wiggle
    const wiggleOffset =
      Math.sin((elapsedTime / 1000) * frequency * 2 * Math.PI) * amplitude;

    // Apply transform
    element.style.transform = `scale(${zoomScale}) translateX(${
      wiggleOffset * 100
    }px)`;

    // Continue the animation
    if (zoomScale < 1.5) {
      // Stop zooming after it reaches max scale
      requestAnimationFrame(animate);
    } else {
      // Keep wiggle effect even after zoom-in completes
      startTime = currentTime; // Reset time for infinite wiggle
      requestAnimationFrame(animate);
    }
  };

  requestAnimationFrame(animate);
};



const onProgress: PrefetchOnProgress = (progress) => {
  // console.log('-----------',progress)
  if (progress.totalBytes === null) {
    // HTTP response has no "Content-Length" header,
    // therefore no relative progress can be calculated.
    console.log('Loaded bytes:', progress.loadedBytes);
    return;
  }
  const percentage = ((progress.loadedBytes / progress.totalBytes) * 100)
  // console.log(
  //   'Loading progress:',
  //   Math.round(percentage),
   
  // );
};