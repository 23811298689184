import { Carousel, Col, Row } from "antd";
import SingleCardStoryBoard from "./singleCardBoard";
import "./sectionStoryBoard.less";
import { useGetVoices } from "@src/api/elevenLabs/getVoices";
import { observer } from "mobx-react-lite";
import { useEffect, useState } from "react";
import { LeftOutlined, RightOutlined } from '@ant-design/icons';

const itemsPerPage = 3;
const SectionStoryBoard = ({ store, scriptDetails }: any) => {
  const { data: aiVoice, isLoading, isSuccess } = useGetVoices();
  const [currentIndex, setCurrentIndex] = useState(0);
  const totalItems = scriptDetails?.data?.length;
  const scrollSmoothly = (direction: "next" | "prev") => {
    const offset = direction === "next" ? 1 : -1;
    const maxIndex = totalItems - itemsPerPage + 1;
    const newIndex = currentIndex + offset;
    if (totalItems < 4) {
      return;
    }
    // Set the new index while keeping it within bounds [0, maxIndex]
    setCurrentIndex(Math.max(0, Math.min(newIndex, maxIndex)));
  };


  return (
    <>
      <div style={{ position: 'relative', marginBlock: '8px' }}>
        <div style={{ display: 'flex', justifyContent: 'end', gap: '20px' , position:'absolute' , width:'36vw'  , zIndex:'2'}}>
          <div
            onClick={() => {
              if (currentIndex !== 0) scrollSmoothly('prev');
            }}
            style={{
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
              cursor: currentIndex === 0 ? 'default' : 'pointer',
            }}
          >
            <LeftOutlined
              style={{
                fontSize: '18px',
                color: currentIndex === 0 ? '#A0A0A0' : 'var(--color-primary-700)',
              }}
            />
            <p
              style={{
                color: currentIndex === 0 ? '#A0A0A0' : 'var(--color-primary-700)',
                cursor: currentIndex === 0 ? 'default' : 'pointer',
              }}
            >
              Prev
            </p>
          </div>

          <div
            onClick={() => {
              if (totalItems - currentIndex > 3) scrollSmoothly('next');
            }}
            style={{
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
              cursor: totalItems - currentIndex < 4 ? 'default' : 'pointer',
            }}
          >
            <RightOutlined
              style={{
                fontSize: '18px', // Customize icon size
                color: totalItems - currentIndex < 4 ? '#A0A0A0' : 'var(--color-primary-700)',
              }}
            />
            <p
              style={{
                color: totalItems - currentIndex < 4 ? '#A0A0A0' : 'var(--color-primary-700)',
                cursor: totalItems - currentIndex < 4 ? 'default' : 'pointer',
              }}
            >
              Next
            </p>
          </div>
        </div>
      </div>
      <div style={{ position: 'relative', height: 'fit-content', display: 'flex', flexDirection: 'column' }}>
        <div style={{ width: '100%', marginTop: '6vh' }}>
          <div
            style={{
              display: "flex",
              transform: `translateX(-${currentIndex * (100 / totalItems)}%)`,
              transition: 'transform 0.5s ease',
              // alignItems: 'center',
              gap: '8px',
              overflowX: 'hidden'
            }}
          // className="custom-scrollbar"
          >
            {scriptDetails?.data?.map((script: any, index: number) => (
              <SingleCardStoryBoard
                key={index}
                store={store}
                lastCard={scriptDetails?.data?.length === (index + 1)}
                script={script}
                scriptIndex={index}
                aiVoice={aiVoice?.voices || []}
              />
            ))}
          </div>
        </div>
      </div>
    </>
  );
};

export default observer(SectionStoryBoard);
