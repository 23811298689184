import { useEffect } from 'react'
import mixpanel from 'mixpanel-browser'

// Set Mixpanel token based on environment variables
const isProduction = process.env.REACT_APP_NODE_ENV === 'prod'

// Choose the Mixpanel token based on the environment
const mixpanel_token = isProduction
  ? process.env.REACT_APP_PUBLIC_MIXPANEL_PROJECT_TOKEN_MASTER
  : process.env.REACT_APP_PUBLIC_MIXPANEL_PROJECT_TOKEN_DEV

// Set Mixpanel debug mode based on environment
const mixpanel_debug = process.env.REACT_APP_NODE_ENV !== 'prod'

const mixpanel_api_host = 'https://proxy-server.brandbooster.ai'

export default function Mixpanel() {
  useEffect(() => {
    mixpanel.init(mixpanel_token as string, {
      api_host: mixpanel_api_host,
      debug: mixpanel_debug,
      track_pageview: false,
      ignore_dnt: true,
      record_sessions_percent: 100,
      record_block_selector: "img"
    });
    mixpanel?.start_session_recording()
  }, [])

  return <></>
}
