export function voiceFilter(aiVoice:any){

const accents = [...new Set(aiVoice.map((voice:any) => voice?.labels?.accent))];
const ages = [...new Set(aiVoice.map((voice:any) => voice?.labels?.age))];
const genders = [...new Set(aiVoice.map((voice:any) => voice?.labels?.gender))];
const languages = [...new Set(aiVoice.map((voice:any) => voice?.fine_tuning?.language))].filter(Boolean);
const usecases = [...new Set(aiVoice.map((voice:any) => voice?.labels?.use_case))].filter(Boolean);

const returnObjects={
    accents:accents,
    ages:ages,
    genders:genders,
    languages:languages,
    usecases:usecases
}
return returnObjects
}

export const languages = [
    { label: "Arabic", value: "ar" },
    { label: "Bulgarian", value: "bg" },
    { label: "Chinese (Simplified)", value: "zh" },
    { label: "Croatian", value: "hr" },
    { label: "Czech", value: "cs" },
    { label: "Danish", value: "da" },
    { label: "Dutch", value: "nl" },
    { label: "English", value: "en" },
    { label: "Filipino (Tagalog)", value: "tl" },
    { label: "Finnish", value: "fi" },
    { label: "French (France, Canada)", value: "fr" },
    { label: "German", value: "de" },
    { label: "Greek", value: "el" },
    { label: "Hindi", value: "hi" },
    { label: "Hungarian", value: "hu" },
    { label: "Indonesian", value: "id" },
    { label: "Italian", value: "it" },
    { label: "Japanese", value: "ja" },
    { label: "Korean", value: "ko" },
    { label: "Malay", value: "ms" },
    { label: "Norwegian", value: "no" },
    { label: "Polish", value: "pl" },
    { label: "Portuguese (Brazil, Portugal)", value: "pt" },
    { label: "Romanian", value: "ro" },
    { label: "Russian", value: "ru" },
    { label: "Slovak", value: "sk" },
    { label: "Spanish (Spain, Latin America)", value: "es" },
    { label: "Swedish", value: "sv" },
    { label: "Tamil", value: "ta" },
    { label: "Turkish", value: "tr" },
    { label: "Ukrainian", value: "uk" },
    { label: "Vietnamese", value: "vi" }
  ];