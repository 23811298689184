export const ReplicaClonedVoices = [
  {replica_id: "r6583a465c", voice_id: "vBKc2FfBKJfcZNyEt1n6"},
  {replica_id: "r79e1c033f", voice_id: "vBKc2FfBKJfcZNyEt1n6"},
  {replica_id: "r63f390d9b", voice_id: "bs3KDkH05RCXCbJKeqU2"},
  {replica_id: "r21027455f", voice_id: "bs3KDkH05RCXCbJKeqU2"},
  {replica_id: "r605415625", voice_id: "vBKc2FfBKJfcZNyEt1n6"},
  {replica_id: "r067b795cc", voice_id: "F6oY9GRj3XjzEXcA4AIl"},
  {replica_id: "r4249d7154", voice_id: "gs0tAILXbY5DNrJrsM6F"},
  {replica_id: "r87e8afa7d", voice_id: "F6oY9GRj3XjzEXcA4AIl"},
  {replica_id: "r5a10b33be", voice_id: "c6SfcYrb2t09NHXiT80T"},
  {replica_id: "r393c989e2", voice_id: "ac7T1wmJB83YYOrxSlEN"},
  {replica_id: "r6c3cc3111", voice_id: "c6SfcYrb2t09NHXiT80T"},
  {replica_id: "r590584193", voice_id: "ac7T1wmJB83YYOrxSlEN"},
  {replica_id: "r4f6470a95", voice_id: "F6oY9GRj3XjzEXcA4AIl"},
  {replica_id: "rd4e6981aa", voice_id: "gs0tAILXbY5DNrJrsM6F"},
  {replica_id: "r3a5283365", voice_id: "F6oY9GRj3XjzEXcA4AIl"},
  {replica_id: "r4e401136b", voice_id: "XA2bIQ92TabjGbpO2xRr"},
  {replica_id: "rbff6415e6", voice_id: "bs3KDkH05RCXCbJKeqU2"},
  {replica_id: "r51cb5ea70", voice_id: "vBKc2FfBKJfcZNyEt1n6"},
  {replica_id: "r9247bcb15", voice_id: "rMKUWjXDUQd4LAVX155A"},
  {replica_id: "r3b3c4f911", voice_id: "3vk47KpWZzIrWkdEhumS"},
  {replica_id: "r5c5d0370b", voice_id: "3XOBzXhnDY98yeWQ3GdM"},
  {replica_id: "re8e740a42", voice_id: "vBKc2FfBKJfcZNyEt1n6"},
  {replica_id: "rfd03e9ebc", voice_id: "F6oY9GRj3XjzEXcA4AIl"},
  {replica_id: "r0104286ea", voice_id: "XA2bIQ92TabjGbpO2xRr"},
  {replica_id: "r2c64b5071", voice_id: "bs3KDkH05RCXCbJKeqU2"},
  {replica_id: "r1fbfc941b", voice_id: "vBKc2FfBKJfcZNyEt1n6"},
  {replica_id: "r767d40a50", voice_id: "ac7T1wmJB83YYOrxSlEN"},
  {replica_id: "r6b7318f8d", voice_id: "c6SfcYrb2t09NHXiT80T"},
  {replica_id: "ref226fe7e", voice_id: "gs0tAILXbY5DNrJrsM6F"},
  {replica_id: "r8bb0646ae", voice_id: "F6oY9GRj3XjzEXcA4AIl"},
  {replica_id: "r86661906c", voice_id: "3vk47KpWZzIrWkdEhumS"},
  {replica_id: "rf8142b7f0", voice_id: "2BJW5coyhAzSr8STdHbE"},
  {replica_id: "rde3b1a18f", voice_id: "3XOBzXhnDY98yeWQ3GdM"},
  {replica_id: "r41c3031c1", voice_id: "CVRACyqNcQefTlxMj9bt"},
  {replica_id: "r2731127d5", voice_id: "CVRACyqNcQefTlxMj9bt"},
  {replica_id: "r9d2a7a53c", voice_id: "NMbn4FNN0acONjKLsueJ"},
  {replica_id: "rbedfcceb3", voice_id: "V87ouR6QgEOMosPFFqIN"},
  {replica_id: "rb508541fb", voice_id: "V87ouR6QgEOMosPFFqIN"},
  {replica_id: "r49f0f6270", voice_id: "NMbn4FNN0acONjKLsueJ"},
  {replica_id: "r594c6a4aa", voice_id: "NMbn4FNN0acONjKLsueJ"},
  {replica_id: "r054afacf7", voice_id: "V87ouR6QgEOMosPFFqIN"},
  {replica_id: "r2bdbdd931", voice_id: "3XOBzXhnDY98yeWQ3GdM"},
  {replica_id: "r2b021a590", voice_id: "cs8ItwSRrDvKm3GWmuNe"},
  {replica_id: "r694955199", voice_id: "3XOBzXhnDY98yeWQ3GdM"},
  {replica_id: "r934c980b4", voice_id: "3XOBzXhnDY98yeWQ3GdM"},
  {replica_id: "r70f15c5b1", voice_id: "CVRACyqNcQefTlxMj9bt"},
  {replica_id: "r74c734ddc", voice_id: "cs8ItwSRrDvKm3GWmuNe"},
  { replica_id: "r3fbe3834a3e", voice_id: "Gqe8GJJLg3haJkTwYj2L"},
  { replica_id: "rd598a103a00", voice_id: "Gqe8GJJLg3haJkTwYj2L"},
  { replica_id: "r6d479c214", voice_id: "F6oY9GRj3XjzEXcA4AIl" },
  { replica_id: "r250b2003c", voice_id: "F6oY9GRj3XjzEXcA4AIl" },
  { replica_id: "rb3121b3c4", voice_id: "luVEyhT3CocLZaLBps8v" },
  { replica_id: "r52da2535a", voice_id: "LtPsVjX1k0Kl4StEMZPK" },
  { replica_id: "rab615a199", voice_id: "S9EGwlCtMF7VXtENq79v" },
  { replica_id: "r725beda81", voice_id: "LtPsVjX1k0Kl4StEMZPK" },
  { replica_id: "re008a6ea6", voice_id: "nk3DjY1m4arWwaptIJmj" },
  { replica_id: "r0f7e90793", voice_id: "nk3DjY1m4arWwaptIJmj" },
  { replica_id: "rceacec30f", voice_id: "0yuXDCeMyqLBTNbylHHb" },
  { replica_id: "rab1105513", voice_id: "DtsPFCrhbCbbJkwZsb3d" },
  { replica_id: "ref9611c10", voice_id: "RZmT8E1mZRZeW14sUf89" },
  { replica_id: "r4c41453d2", voice_id: "DaM2B08dTIxd3wC4wKKw" },
  { replica_id: "r74df1ac87", voice_id: "S9EGwlCtMF7VXtENq79v" },
  { replica_id: "r43d31a795", voice_id: "0yuXDCeMyqLBTNbylHHb" },
  { replica_id: "r604520422", voice_id: "LtPsVjX1k0Kl4StEMZPK" },
  { replica_id: "r9191662c8", voice_id: "RZmT8E1mZRZeW14sUf89" },
  { replica_id: "r654493d9e", voice_id: "DtsPFCrhbCbbJkwZsb3d" },
  { replica_id: "r5b3265ee1", voice_id: "nk3DjY1m4arWwaptIJmj" },
  { replica_id: "r40ee7e29b", voice_id: "nk3DjY1m4arWwaptIJmj" },
  { replica_id: "r084238898", voice_id: "uaX0YliCmDO75lRrUQE8" },
  { replica_id: "r8bfa69a42", voice_id: "LtPsVjX1k0Kl4StEMZPK" },
  { replica_id: "r660c4f3ba", voice_id: "LtPsVjX1k0Kl4StEMZPK" },
  { replica_id: "r445a7952e", voice_id: "uaX0YliCmDO75lRrUQE8" },
  { replica_id: "r243eed46c", voice_id: "luVEyhT3CocLZaLBps8v" },
  { replica_id: "r105cfc41a", voice_id: "WtA85syCrJwasGeHGH2p" },
  { replica_id: "rb8a2b77cb", voice_id: "nk3DjY1m4arWwaptIJmj" },
  { replica_id: "r4e34d2d67", voice_id: "eBvoGh8YGJn1xokno71w" },
  { replica_id: "r7dbef2aab", voice_id: "luVEyhT3CocLZaLBps8v" },
  { replica_id: "rca5e9b9dc", voice_id: "eBvoGh8YGJn1xokno71w" },
  { replica_id: "r8e839ebb6", voice_id: "uaX0YliCmDO75lRrUQE8" },
  { replica_id: "r637dd0cdd", voice_id: "0yuXDCeMyqLBTNbylHHb" },
  { replica_id: "re602baff7", voice_id: "DtsPFCrhbCbbJkwZsb3d" },
  { replica_id: "r09d16d4b6", voice_id: "S9EGwlCtMF7VXtENq79v" },
  { replica_id: "rbb0f535dd", voice_id: "0yuXDCeMyqLBTNbylHHb" },
  { replica_id: "ra54d1d861", voice_id: "LtPsVjX1k0Kl4StEMZPK" },
  { replica_id: "rfb51183fe", voice_id: "RZmT8E1mZRZeW14sUf89" },
  { replica_id: "r094ecc9a5", voice_id: "cs8ItwSRrDvKm3GWmuNe" },
  { replica_id: "ra0322b20e", voice_id: "tpFVjZdYBvOaWaUIRHRz" },
  { replica_id: "r4c13091db", voice_id: "DaM2B08dTIxd3wC4wKKw" },
  { replica_id: "r336b27aff", voice_id: "cs8ItwSRrDvKm3GWmuNe" },
  { replica_id: "r1e69411f2", voice_id: "FVQMzxJGPUBtfz1Azdoy" },
  { replica_id: "r43f5154d6", voice_id: "FVQMzxJGPUBtfz1Azdoy" },
  { replica_id: "rf725707a5", voice_id: "DaM2B08dTIxd3wC4wKKw" },
  { replica_id: "rc46b5d772", voice_id: "FVQMzxJGPUBtfz1Azdoy" },
  { replica_id: "r315bff4de", voice_id: "FVQMzxJGPUBtfz1Azdoy" },
  { replica_id: "r1b15de50c", voice_id: "EfHLo9HwjoE6N5RpTnN8" },
  { replica_id: "r9c69e58e2", voice_id: "uaX0YliCmDO75lRrUQE8" },
  { replica_id: "rbc674de11", voice_id: "EfHLo9HwjoE6N5RpTnN8" },
  { replica_id: "r40f2da1a2", voice_id: "EfHLo9HwjoE6N5RpTnN8" },
  { replica_id: "r6f41becb2", voice_id: "uaX0YliCmDO75lRrUQE8" },
  { replica_id: "r1a667ea75", voice_id: "DaM2B08dTIxd3wC4wKKw" },
  { replica_id: "r93bd4e488", voice_id: "EfHLo9HwjoE6N5RpTnN8" },
  { replica_id: "r4da784871", voice_id: "eBvoGh8YGJn1xokno71w" },
  { replica_id: "r89329f4fd", voice_id: "eBvoGh8YGJn1xokno71w" },
  { replica_id: "r18d46c93e", voice_id: "FVQMzxJGPUBtfz1Azdoy" },
  { replica_id: "r10e648bfa", voice_id: "FVQMzxJGPUBtfz1Azdoy" },
  { replica_id: "rc5ab5fe34", voice_id: "EfHLo9HwjoE6N5RpTnN8" },
  {replica_id: "rce846fcf7", voice_id: "luVEyhT3CocLZaLBps8v"},
  { replica_id: "r0a8dd87628f", voice_id: "cs8ItwSRrDvKm3GWmuNe" },
  { replica_id: "ra5e60eeb07f", voice_id: "V87ouR6QgEOMosPFFqIN" },
  { replica_id: "ra8f6684f6d1", voice_id: "V4xDoasPVuLdXfPPA6YE" },
  { replica_id: "r492a86a1f2d", voice_id: "NMbn4FNN0acONjKLsueJ" },
  { replica_id: "r6e055828f63", voice_id: "0yuXDCeMyqLBTNbylHHb" },
  { replica_id: "r501f5914091", voice_id: "gs0tAILXbY5DNrJrsM6F" },
  { replica_id: "r1653e6e84ad", voice_id: "LtPsVjX1k0Kl4StEMZPK" },
  { replica_id: "rd1b92a0999a", voice_id: "bs3KDkH05RCXCbJKeqU2" },
  { replica_id: "rb2606b71887", voice_id: "RZmT8E1mZRZeW14sUf89" },
  { replica_id: "r638794ceb2a", voice_id: "F6oY9GRj3XjzEXcA4AIl" },
  { replica_id: "r1dbdb02417a", voice_id: "DaM2B08dTIxd3wC4wKKw" },
  { replica_id: "r70c6bc650af", voice_id: "3XOBzXhnDY98yeWQ3GdM" }
]