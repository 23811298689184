
export async function loadFontsCustom(store: any) {
    await store.addFont({
        fontFamily: 'Bangers',
        url: 'https://fonts.googleapis.com/css2?family=Bangers',
      });
      
    //   await store.addFont({
    //     fontFamily: 'Arial',
    //     url: 'https://fonts.googleapis.com/css2?family=Arial',
    //   });
      
      await store.addFont({
        fontFamily: 'Comic Neue',
        url: 'https://fonts.googleapis.com/css2?family=Comic+Neue',
      });
      
      await store.addFont({
        fontFamily: 'Inter',
        url: 'https://fonts.googleapis.com/css2?family=Inter',
      });
      
      await store.addFont({
        fontFamily: 'Lato',
        url: 'https://fonts.googleapis.com/css2?family=Lato',
      });
      
      await store.addFont({
        fontFamily: 'Merriweather',
        url: 'https://fonts.googleapis.com/css2?family=Merriweather',
      });
      
      await store.addFont({
        fontFamily: 'Montserrat',
        url: 'https://fonts.googleapis.com/css2?family=Montserrat',
      });
      
      await store.addFont({
        fontFamily: 'Mulish',
        url: 'https://fonts.googleapis.com/css2?family=Mulish',
      });
      
      await store.addFont({
        fontFamily: 'Open Sans',
        url: 'https://fonts.googleapis.com/css2?family=Open+Sans',
      });
      
      await store.addFont({
        fontFamily: 'Oswald',
        url: 'https://fonts.googleapis.com/css2?family=Oswald',
      });
      
      await store.addFont({
        fontFamily: 'Poppins',
        url: 'https://fonts.googleapis.com/css2?family=Poppins',
      });
      
      await store.addFont({
        fontFamily: 'Roboto',
        url: 'https://fonts.googleapis.com/css2?family=Roboto',
      });
      
      await store.addFont({
        fontFamily: 'Source Sans Pro',
        url: 'https://fonts.googleapis.com/css2?family=Source+Sans+Pro',
      });
      
      await store.addFont({
        fontFamily: 'Varela Round',
        url: 'https://fonts.googleapis.com/css2?family=Varela+Round',
      });

      await store.addFont({
        fontFamily: 'Konga next',
        url: 'fonts/Konga Next.otf',
      })
      await store.addFont({
        fontFamily: 'THE BOLD FONT',
        url: 'fonts/THE BOLD FONT.otf',
      })
      
} 
